@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    75% {
        stroke-dashoffset: 300;
        fill: transparent;
        stroke: #000000;
    }

    100% {
        stroke-dashoffset: 300;
        stroke: transparent;
        fill: #FFF;
    }
}

#home-page {
    path, polygon {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation-name: dash;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 2s;
    }
}
