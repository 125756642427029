.tag {
    &.tag-php {
        background: #8892bf;
        color: lighten(#8892bf, 30%);
    }

    &.tag-code-igniter {
        background: #dd4814;
        color: lighten(#dd4814, 30%);
    }

    &.tag-html {
        background: #e44d26;
        color: lighten(#e44d26, 30%);
    }

    &.tag-css {
        background: #1572b6;
        color: lighten(#1572b6, 30%);
    }

    &.tag-photoshop {
        background: #3f8ddf;
        color: lighten(#3f8ddf, 30%);
    }

    &.tag-illustrator {
        background: #fdae37;
        color: lighten(#fdae37, 30%);
    }

    &.tag-wordpress {
        background: #21759a;
        color: lighten(#21759a, 30%);
    }

    &.tag-sass {
        background: #c6538c;
        color: lighten(#c6538c, 30%);
    }

    &.tag-javascript {
        background: #f7df1e;
        color: darken(#f7df1e, 30%);
    }

    &.tag-indesign {
        background: #ff3d90;
        color: lighten(#ff3d90, 30%);
    }
}
