.charts-langs {
    padding-left: 0;
    width: 100%;

    .chart {
        background: red;
        width: 60px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
        right: -5px;
        top: -5px;

        &:after {
            content: " ";
            display: block;
            position: relative;
            color: #FFF;
            height: 100%;
            width: 100%;
            right: 5px;
            top: 5px;
            z-index: 1;
            background: #000;
        }
    }

    li {
        display: inline-block;
        list-style: none;
        height: 200px;
        text-align: center;
        margin-right: 20px;
        font-weight: bold;

        $percentages: 95 95 70 80 95 80 70 80;
        $colors: #ef652a #1572b6 #c6538c #ffb615 #0769ad #8892bf #f4645f #719e40;

        @for $i from 1 through length($percentages) {
            $p: nth($percentages, $i);
            &:nth-child(#{$i}) .chart {
                &:after { content: '#{$p}%' };
                height: #{$p}%;
            }
        }

        @each $color in $colors {
            $i: index($colors, $color);
            &:nth-child(#{$i}) .chart {
                &:after { background: $color };
                background: darken( $color, 15%);
            }
        }
    }
}

.chart-softwares {
    padding-left: 0;

    li {
        display: inline-block;
        text-align: center;
        max-width: 32%;

        $colors: #39c4fb #ff701c #ff3b91 #db7cff #ca9dff;

        @each $color in $colors {
            $i: index($colors, $color);
            &:nth-child(#{$i}) {
                color: darken( $color, 25%);
            }
        }
    }
}
