@keyframes fade {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.portfolio-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-left: 0;

	h2 {
		font-family: 'Fontin', sans-serif;
	}

	article {
		display: inline-block;
		border-radius: 50%;
		margin: 0 auto;
		margin-bottom: 40px;
		width: 100%;

		@include breakpoint($phablet) {
			margin-left: 0;
			margin-right: 0;
			overflow: hidden;
			width: auto;
		}
	}

	a {
		position: relative;
		display: block;
		float: left;
		border-radius: 50%;
		padding: 10px;
		border: 1px solid #333;
		
		transform: translateZ(0);

		max-width: 300px;

		width: 50%;

		@include breakpoint($phablet) {
			width: auto;
			overflow: hidden;

			&:after {
				position: absolute;
				top: -25%;
				left: -25%;
				content: " ";
				width: 600px;
				height: 600px;
				transform: rotate(-45deg) translateY(75%);
				background: rgba($fourth-color-lighten, 0.8);
				transition: transform .4s;
			}

			&:hover {
				&:after {
					transform: rotate(-45deg) translateY(0);
				}
			}
		}

		.title {
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
			z-index: 300;
			color: #333;
			font-size: 1.5em;
			width: 100%;
			padding-left: 15px;
			

			@include breakpoint($phablet) {	
				left: 50%;
				top: 50%;
				width: 80%;
				transform: translate(-50%, -50%);
				text-align: center;

				> * {
					transform: translateY(-30px);
					opacity: 0;
				}
			}
		}

		.service-icon {
			display: none;

			@include breakpoint($phablet) {
				display: block;
			}
		}

		.name {
			font-size: 1.2em;
			letter-spacing: -1px;
			margin-bottom: 0;

			&:after {
				display: none;
			}
		}

		.tagline {
			font-style: italic;
			font-size: .8em;
			margin-top: 0;
		}

		@include breakpoint($phablet) {
			&:hover {
				.title > * {
					animation-name: fade;
					animation-duration: .4s;
					animation-fill-mode: forwards;
					
					@for $i from 1 through 4 {
						&:nth-child(#{$i}) { animation-delay: $i * 0.1s }
					}
				}
			}
		}
	}

	& .code-project a:after { background: rgba($third-color-lighten, 0.8) }

	img {
		border-radius: 50%;
		display: block;
	}
}

.service-icon {
	font-size: 50px;
	text-align: center;
	display: block;
}
