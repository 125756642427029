body {
	font-family: 'Open Sans', sans-serif;
	color: $text-color;
	font-size: .9em;
}

h1, h2, h3,h4, h5, h6, button, input[type=submit] {
	font-family: 'Open Sans Condensed', sans-serif;
	color: $text-color;
}

h1 {
	font-family: 'Fontin', sans-serif;
	font-size: 6em;
	letter-spacing: -3px;

	&:after {
		content: " ";
		height: 3px;
		width: 140px;
		display: block;
		margin-top: -5px;
		background: $main-color;
	}
}

p, li {
	line-height: 1.6em;
}

p {
	margin-bottom: 1.6em;
}
