.main-nav {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(200%, -50%);
	z-index: 340;
	
	text-align: center;
	width: $menu-width;
	padding: 10px;
	transition: transform $animation-time;

	.nav-opened & {
		transform: translate(-50%, -50%);
	}

	ul {
		width: 100%;
		padding: 0 15px;
	}

	a {
		padding: 10px;
		display: block;
		color: #FFF;
		text-transform: uppercase;
		transition: color 1s;

		@include breakpoint($phablet) {
			color: $main-color;
		}

		&:hover {
			text-decoration: none;
		}

		.sticky & {
			transition: .3s all;
			color: #FFF;
		}
	}

	li {
		border-bottom: 1px solid #3567AF;
		list-style: none;
		display: block;
		margin-left: 0;
		text-align: center;

		&:last-of-type {
			border-bottom: none;
		}
	}

	@include breakpoint($phablet) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 0;
		padding-top: 5px;
		transform: none;
		width: auto;

		ul {
			text-align: right;
			margin: 0;
			right: 0;
			padding-left: 0;
		}

		li {
			display: inline-block;
			margin-left: 20px;
			border-bottom: none;
		}
	}
}

body > header,
body > .container {
	transition: transform $animation-time;
}

@include breakpoint( $mobile ) {
	body.nav-opened > header,
	body.nav-opened > .container {
		
		// Change the animation position of menu
		//transform: translateX($menu-width * if($menu-position == right, -1, 1));
	}
}

.menu-overlay {
	position: fixed;
	right: 10px;
	top: 10px;
	z-index: 300;
	background: #3FACE2;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	transform: scale(1);
	transition: transform .2s;

	.nav-opened & {
		transform: scale(50);
		transition: transform .5s;
	}

	@include breakpoint($phablet){
		display: none;
	}
}
