.social {
    padding-left: 0;

    li {
        display: inline-block;
        font-size: 25px;
        text-align: center;
        margin-right: 5px;

        a {
            display: block;
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: all .4s;
            color: gray;

            &:after, &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid gray;
                transition: all .4s;
            }

            &:before {
                width: 80%;
                height: 80%;
            }

            &:hover:before {
                border-color: #FFF;
            }

            $socials: linkedin github twitter pinterest;
            $colors: #1a84bc #000 #1da1f2 #d01d15;

            @for $i from 1 through length($socials) {
                $social:  nth($socials, $i);
                $color: nth($colors, $i);
                
                &.social-#{$social} {
                    &:hover {
                        color: #FFF;
                        background: $color;
                        
                        &:after {
                            border-color: $color;
                        }
                    }
                }
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
