.mail-contact {
    font-size: 1.6em;

    a {
        color: gray;
    }
}

.icon-envelope {
    color: gray;
    font-size: 1em;
    padding: 7px;
}


#contato-page {
    h2 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 200;
        font-size: 3em;
        margin-top: 0;
    }
}
