.menu-trigger {
    //position: fixed;
    //top: 18px;
    //right: 5%;
    position: fixed;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
    z-index: 400;
    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    //background: #CCC;
    cursor: pointer;

    @include breakpoint( $phablet ) {
        display: none;
    }

    .menu-icon {
        /* icon created in CSS */
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        display: inline-block;
        width: 25px;
        height: 4px;
        background-color: #ffffff;
        z-index: 10;

        &::before, &::after {
            /* upper and lower lines of the menu icon */
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #FFF;
            transition: transform $animation-time;
        }
     
        &::before {
            transform: translateY(-8px) rotate(0deg);
        }
         
        &::after {
            transform: translateY(8px) rotate(0deg);
        }
    }

    .nav-opened & {
        .menu-icon {
            background-color: transparent;

            &::before {
                transform: translateY(0) rotate(45deg);
            }
             
            &::after {
                transform: translateY(0) rotate(-45deg);
            }
        }
    }
}
