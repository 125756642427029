input, select, textarea {
	background: #EEE;
	border: 1px solid #AFAFAF;
	padding: 15px 10px;
	width: 100%;
	display: block;

	&.error {
		border-color: red;
		background: #ffcece;
	}
}

textarea {
	min-height: 150px;
}

button, input[type=submit], button[type=submit] {
	padding: 15px 20px;
	border: none;
	background: $main-color;
	color: #FFF;
	width: 100%;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	font-size: 1.2em;
}

form .field {
	margin-bottom: 10px;
}

label.error {
	color: red;
}
