.site-header {
	padding: 10px 0;
	transition: .3s all;
	position: fixed;
	width: 100%;
	z-index: 200;
	@include cf();

	.container {
		position: relative;
	}

	&.active {
		//transform: translate(-90%,0);
	}
}

.top-bar-bg {
	background: darken($main-color, 60%);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    transform-origin: 0% 0%;
	transform: rotateX(180deg);
	transform-style: preserve-3d;
	transition: all 1s;


	.sticky & {
		background: $main-color;
		transform: rotateX(0deg);
	}
}

.brand {
	max-width: 220px;

	a {
		display: block;
		width: 100%;
		height: 50px;
	}

	img {
		transition: opacity 1s;
	}
	
	.sticky & {
		background: transparent url('/svg/logotipo.svg?c=fff') left top no-repeat;
		background-size: 100%;
		
		img {
			opacity: 0;
		}
	}
	
}
