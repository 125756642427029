
@font-face {
    font-family: 'Fontin Small Caps';
    src: url('../fonts/fontin-smallcaps-webfont.eot');
    src: local('Fontin Small Caps'), url('../fonts/fontin-smallcaps-webfont.woff') format('woff'), url('../fonts/fontin-smallcaps-webfont.ttf') format('truetype'), url('../fonts/fontin-smallcaps-webfont.svg#webfont1WYomNdM') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fontin';
    src: url('../fonts/fontin-regular-webfont.eot');
    src: local('Fontin'), url('../fonts/fontin-regular-webfont.woff') format('woff'), url('../fonts/fontin-regular-webfont.ttf') format('truetype'), url('../fonts/fontin-regular-webfont.svg#webfontosjbZUhE') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fontin';
    src: url('../fonts/fontin-italic-webfont.eot');
    src: local('Fontin'), url('../fonts/fontin-italic-webfont.woff') format('woff'), url('../fonts/fontin-italic-webfont.ttf') format('truetype'), url('../fonts/fontin-italic-webfont.svg#webfontkY6wBQg6') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Fontin';
    src: url('../fonts/fontin-bold-webfont.eot');
    src: local('Fontin'), url('../fonts/fontin-bold-webfont.woff') format('woff'), url('../fonts/fontin-bold-webfont.ttf') format('truetype'), url('../fonts/fontin-bold-webfont.svg#webfont5kwPPRqy') format('svg');
    font-weight: bold;
    font-style: normal;
}

