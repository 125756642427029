@charset "UTF-8";
/*  Syntax Quick Reference
  --------------------------
  column($ratios: 1, $offset: 0, $cycle: 0, $uncycle: 0, $gutter: $jeet-gutter)
  span($ratio: 1, $offset: 0)
  shift($ratios: 0, $col_or_span: column, $gutter: $jeet-gutter)
  unshift()
  edit()
  center($max_width: 1410px, $pad: 0)
  stack($pad: 0, $align: false)
  unstack()
  align($direction: both)
  cf()
*/
/**
 * Grid settings.
 * All values are defaults and can therefore be easily overidden.
 */
/**
 * List functions courtesy of the wonderful folks at Team Sass.
 * Check out their awesome grid: Singularity.
 */
/**
 * Get  percentage from a given ratio.
 * @param {number} [$ratio=1] - The column ratio of the element.
 * @returns {number} - The percentage value.
 */
/**
 * Work out the column widths based on the ratio and gutter sizes.
 * @param {number} [$ratios=1] - The column ratio of the element.
 * @param {number} [$gutter=$jeet-gutter] - The gutter for the column.
 * @returns {list} $width $gutter - A list containing the with and gutter for the element.
 */
/**
 * Get the set layout direction for the project.
 * @returns {string} $direction - The layout direction.
 */
/**
 * Replace a specified list value with a new value (uses built in set-nth() if available)
 * @param {list} $list - The list of values you want to alter.
 * @param {number} $index - The index of the list item you want to replace.
 * @param {*} $value - The value you want to replace $index with.
 * @returns {list} $list - The list with the value replaced or removed.
 * @warn if an invalid index is supplied.
 */
/**
 * Reverse a list (progressively enhanced for Sass 3.3)
 * @param {list} $list - The list of values you want to reverse.
 * @returns {list} $result - The reversed list.
 */
/**
 * Get the opposite direction to a given value.
 * @param {string} $dir - The direction you want the opposite of.
 * @returns {string} - The opposite direction to $dir.
 * @warn if an incorrect string is provided.
 */
/**
 * Style an element as a column with a gutter.
 * @param {number} [$ratios=1] - A width relative to its container as a fraction.
 * @param {number} [$offset=0] - A offset specified as a fraction (see $ratios).
 * @param {number} [$cycle=0] - Easily create an nth column grid where $cycle equals the number of columns.
 * @param {number} [$uncycle=0] - Undo a previous cycle value to allow for a new one.
 * @param {number} [$gutter=$jeet-gutter] - Specify the gutter width as a percentage of the containers width.
 */
/**
 * An alias for the column mixin.
 * @param [$args...] - All arguments get passed through to column().
 */
/**
 * Get the width of a column and nothing else.
 * @param {number} [$ratios=1] - A width relative to its container as a fraction.
 * @param {number} [$gutter=$jeet-gutter] - Specify the gutter width as a percentage of the containers width.
 */
/**
 * Get the gutter size of a column and nothing else.
 * @param {number} [ratios=1] - A width relative to its container as a fraction.
 * @param {number} [gutter=jeet.gutter] - Specify the gutter width as a percentage of the containers width.
 */
/**
 * An alias for the column-width function.
 * @param [$args...] - All arguments get passed through to column().
 */
/**
 * An alias for the column-gutter function.
 * @param [$args...] - All arguments get passed through to column().
 */
/**
 * Style an element as a column without any gutters for a seamless row.
 * @param {number} [$ratios=1] - A width relative to its container as a fraction.
 * @param {number} [$offset=0] - A offset specified as a fraction (see $ratios).
 * @param {number} [cycle=0] - Easily create an nth column grid where cycle equals the number of columns.
 * @param {number} [uncycle=0] - Undo a previous cycle value to allow for a new one.
 */
/**
 * Reorder columns without altering the HTML.
 * @param {number} [$ratios=0] - Specify how far along you want the element to move.
 * @param {string} [$col-or-span=column] - Specify whether the element has a gutter or not.
 * @param {number} [$gutter=$jeet-gutter] - Specify the gutter width as a percentage of the containers width.
 */
/**
 * Reset an element that has had shift() applied to it.
 */
/**
 * View the grid and its layers for easy debugging.
 * @param {string} [$color=black] - The background tint applied.
 * @param {boolean} [$important=false] - Whether to apply the style as !important.
 */
/**
 *  Alias for edit().
 */
/**
 * Horizontally center an element.
 * @param {number} [$max-width=1410px] - The max width the element can be.
 * @param {number} [$pad=0] - Specify the element's left and right padding.
 */
/**
 * Uncenter an element.
 */
/**
 * Stack an element so that nothing is either side of it.
 * @param {number} [$pad=0] - Specify the element's left and right padding.
 * @param {boolean/string} [$align=false] - Specify the text align for the element.
 */
/**
 * Unstack an element.
 */
/**
 * Center an element on either or both axes.
 * @requires A parent container with relative positioning.
 * @param {string} [$direction=both] - Specify which axes to center the element on.
 */
/**
 * Apply a clearfix to an element.
 */
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

*,
*::after,
*::before {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto !important; }

@font-face {
  font-family: 'Fontin Small Caps';
  src: url("../fonts/fontin-smallcaps-webfont.eot");
  src: local("Fontin Small Caps"), url("../fonts/fontin-smallcaps-webfont.woff") format("woff"), url("../fonts/fontin-smallcaps-webfont.ttf") format("truetype"), url("../fonts/fontin-smallcaps-webfont.svg#webfont1WYomNdM") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Fontin';
  src: url("../fonts/fontin-regular-webfont.eot");
  src: local("Fontin"), url("../fonts/fontin-regular-webfont.woff") format("woff"), url("../fonts/fontin-regular-webfont.ttf") format("truetype"), url("../fonts/fontin-regular-webfont.svg#webfontosjbZUhE") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Fontin';
  src: url("../fonts/fontin-italic-webfont.eot");
  src: local("Fontin"), url("../fonts/fontin-italic-webfont.woff") format("woff"), url("../fonts/fontin-italic-webfont.ttf") format("truetype"), url("../fonts/fontin-italic-webfont.svg#webfontkY6wBQg6") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Fontin';
  src: url("../fonts/fontin-bold-webfont.eot");
  src: local("Fontin"), url("../fonts/fontin-bold-webfont.woff") format("woff"), url("../fonts/fontin-bold-webfont.ttf") format("truetype"), url("../fonts/fontin-bold-webfont.svg#webfont5kwPPRqy") format("svg");
  font-weight: bold;
  font-style: normal; }

.container {
  *zoom: 1;
  width: auto;
  max-width: 1180px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  padding: 0 10px; }
  .container:before, .container:after {
    content: '';
    display: table; }
  .container:after {
    clear: both; }

@media (min-width: 1000px) {
  .col3-4 {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 74.25%;
    margin-left: 0%;
    margin-right: 3%; }
    .col3-4:before, .col3-4:after {
      content: '';
      display: table; }
    .col3-4:after {
      clear: both; }
    .col3-4:last-child {
      margin-right: 0%; }
  .col1-4 {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 22.75%;
    margin-left: 0%;
    margin-right: 3%; }
    .col1-4:before, .col1-4:after {
      content: '';
      display: table; }
    .col1-4:after {
      clear: both; }
    .col1-4:last-child {
      margin-right: 0%; }
  .col1-2 {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 48.5%;
    margin-left: 0%;
    margin-right: 3%; }
    .col1-2:before, .col1-2:after {
      content: '';
      display: table; }
    .col1-2:after {
      clear: both; }
    .col1-2:last-child {
      margin-right: 0%; }
  .main-content {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 65.66667%;
    margin-left: 0%;
    margin-right: 3%;
    position: relative;
    left: 34.33333%; }
    .main-content:before, .main-content:after {
      content: '';
      display: table; }
    .main-content:after {
      clear: both; }
    .main-content:last-child {
      margin-right: 0%; }
  .side-bar {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 22.75%;
    margin-left: 0%;
    margin-right: 3%;
    position: relative;
    left: -68.66667%; }
    .side-bar:before, .side-bar:after {
      content: '';
      display: table; }
    .side-bar:after {
      clear: both; }
    .side-bar:last-child {
      margin-right: 0%; } }

@font-face {
  font-family: 'rriogo';
  src: url("../fonts/rriogo.eot?llrdwo");
  src: url("../fonts/rriogo.eot?llrdwo#iefix") format("embedded-opentype"), url("../fonts/rriogo.ttf?llrdwo") format("truetype"), url("../fonts/rriogo.woff?llrdwo") format("woff"), url("../fonts/rriogo.svg?llrdwo#rriogo") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'rriogo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-link-external:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-profile:before {
  content: ""; }

.icon-briefcase:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-dribbble:before {
  content: ""; }

.icon-google-plus:before {
  content: ""; }

.icon-embed:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-behance:before {
  content: ""; }

.icon-youtube-play:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-github:before {
  content: ""; }

.icon-code:before {
  content: ""; }

.icon-design:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-profile:before {
  content: ""; }

.icon-briefcase:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-dribbble:before {
  content: ""; }

.icon-google-plus:before {
  content: ""; }

.icon-embed:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-behance:before {
  content: ""; }

.icon-youtube-play:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-github:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-pinterest-p:before {
  content: ""; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

@media (min-width: 550px) {
  .mobile-only {
    display: none; } }

@media (max-width: 550px) {
  .no-mobile {
    display: none; } }

.stuck {
  position: fixed;
  top: 0; }

.footer {
  text-align: center; }

input, select, textarea {
  background: #EEE;
  border: 1px solid #AFAFAF;
  padding: 15px 10px;
  width: 100%;
  display: block; }
  input.error, select.error, textarea.error {
    border-color: red;
    background: #ffcece; }

textarea {
  min-height: 150px; }

button, input[type=submit], button[type=submit] {
  padding: 15px 20px;
  border: none;
  background: #2f6db5;
  color: #FFF;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 1.2em; }

form .field {
  margin-bottom: 10px; }

label.error {
  color: red; }

a {
  color: #2f6db5;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.mail-contact {
  font-size: 1.6em; }
  .mail-contact a {
    color: gray; }

.icon-envelope {
  color: gray;
  font-size: 1em;
  padding: 7px; }

#contato-page h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 3em;
  margin-top: 0; }

.menu-trigger {
  position: fixed;
  top: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
  z-index: 400;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer; }
  @media (min-width: 550px) {
    .menu-trigger {
      display: none; } }
  .menu-trigger .menu-icon {
    /* icon created in CSS */
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    width: 25px;
    height: 4px;
    background-color: #ffffff;
    z-index: 10; }
    .menu-trigger .menu-icon::before, .menu-trigger .menu-icon::after {
      /* upper and lower lines of the menu icon */
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      transition: transform 0.3s; }
    .menu-trigger .menu-icon::before {
      transform: translateY(-8px) rotate(0deg); }
    .menu-trigger .menu-icon::after {
      transform: translateY(8px) rotate(0deg); }
  .nav-opened .menu-trigger .menu-icon {
    background-color: transparent; }
    .nav-opened .menu-trigger .menu-icon::before {
      transform: translateY(0) rotate(45deg); }
    .nav-opened .menu-trigger .menu-icon::after {
      transform: translateY(0) rotate(-45deg); }

.tag.tag-php {
  background: #8892bf;
  color: #ebedf5; }

.tag.tag-code-igniter {
  background: #dd4814;
  color: #f5ae95; }

.tag.tag-html {
  background: #e44d26;
  color: #f5bdae; }

.tag.tag-css {
  background: #1572b6;
  color: #75bbef; }

.tag.tag-photoshop {
  background: #3f8ddf;
  color: #c2dbf5; }

.tag.tag-illustrator {
  background: #fdae37;
  color: #ffebce; }

.tag.tag-wordpress {
  background: #21759a;
  color: #73bfe1; }

.tag.tag-sass {
  background: #c6538c;
  color: #ecc6d9; }

.tag.tag-javascript {
  background: #f7df1e;
  color: #786b04; }

.tag.tag-indesign {
  background: #ff3d90;
  color: #ffd6e8; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  font-size: .9em; }

h1, h2, h3, h4, h5, h6, button, input[type=submit] {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #333; }

h1 {
  font-family: 'Fontin', sans-serif;
  font-size: 6em;
  letter-spacing: -3px; }
  h1:after {
    content: " ";
    height: 3px;
    width: 140px;
    display: block;
    margin-top: -5px;
    background: #2f6db5; }

p, li {
  line-height: 1.6em; }

p {
  margin-bottom: 1.6em; }

.charts-langs {
  padding-left: 0;
  width: 100%; }
  .charts-langs .chart {
    background: red;
    width: 60px;
    margin: 0 auto;
    z-index: 10;
    position: relative;
    right: -5px;
    top: -5px; }
    .charts-langs .chart:after {
      content: " ";
      display: block;
      position: relative;
      color: #FFF;
      height: 100%;
      width: 100%;
      right: 5px;
      top: 5px;
      z-index: 1;
      background: #000; }
  .charts-langs li {
    display: inline-block;
    list-style: none;
    height: 200px;
    text-align: center;
    margin-right: 20px;
    font-weight: bold; }
    .charts-langs li:nth-child(1) .chart {
      height: 95%; }
      .charts-langs li:nth-child(1) .chart:after {
        content: "95%"; }
    .charts-langs li:nth-child(2) .chart {
      height: 95%; }
      .charts-langs li:nth-child(2) .chart:after {
        content: "95%"; }
    .charts-langs li:nth-child(3) .chart {
      height: 70%; }
      .charts-langs li:nth-child(3) .chart:after {
        content: "70%"; }
    .charts-langs li:nth-child(4) .chart {
      height: 80%; }
      .charts-langs li:nth-child(4) .chart:after {
        content: "80%"; }
    .charts-langs li:nth-child(5) .chart {
      height: 95%; }
      .charts-langs li:nth-child(5) .chart:after {
        content: "95%"; }
    .charts-langs li:nth-child(6) .chart {
      height: 80%; }
      .charts-langs li:nth-child(6) .chart:after {
        content: "80%"; }
    .charts-langs li:nth-child(7) .chart {
      height: 70%; }
      .charts-langs li:nth-child(7) .chart:after {
        content: "70%"; }
    .charts-langs li:nth-child(8) .chart {
      height: 80%; }
      .charts-langs li:nth-child(8) .chart:after {
        content: "80%"; }
    .charts-langs li:nth-child(1) .chart {
      background: #be430e; }
      .charts-langs li:nth-child(1) .chart:after {
        background: #ef652a; }
    .charts-langs li:nth-child(2) .chart {
      background: #0d4771; }
      .charts-langs li:nth-child(2) .chart:after {
        background: #1572b6; }
    .charts-langs li:nth-child(3) .chart {
      background: #9a3366; }
      .charts-langs li:nth-child(3) .chart:after {
        background: #c6538c; }
    .charts-langs li:nth-child(4) .chart {
      background: #c88900; }
      .charts-langs li:nth-child(4) .chart:after {
        background: #ffb615; }
    .charts-langs li:nth-child(5) .chart {
      background: #043c63; }
      .charts-langs li:nth-child(5) .chart:after {
        background: #0769ad; }
    .charts-langs li:nth-child(6) .chart {
      background: #5865a3; }
      .charts-langs li:nth-child(6) .chart:after {
        background: #8892bf; }
    .charts-langs li:nth-child(7) .chart {
      background: #ef1f17; }
      .charts-langs li:nth-child(7) .chart:after {
        background: #f4645f; }
    .charts-langs li:nth-child(8) .chart {
      background: #4a682a; }
      .charts-langs li:nth-child(8) .chart:after {
        background: #719e40; }

.chart-softwares {
  padding-left: 0; }
  .chart-softwares li {
    display: inline-block;
    text-align: center;
    max-width: 32%; }
    .chart-softwares li:nth-child(1) {
      color: #0480b1; }
    .chart-softwares li:nth-child(2) {
      color: #9c3a00; }
    .chart-softwares li:nth-child(3) {
      color: #bb0052; }
    .chart-softwares li:nth-child(4) {
      color: #b600fc; }
    .chart-softwares li:nth-child(5) {
      color: #851eff; }

@keyframes dash {
  0% {
    stroke-dashoffset: 1000; }
  75% {
    stroke-dashoffset: 300;
    fill: transparent;
    stroke: #000000; }
  100% {
    stroke-dashoffset: 300;
    stroke: transparent;
    fill: #FFF; } }

#home-page path, #home-page polygon {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation-name: dash;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 2s; }

@keyframes fade {
  from {
    transform: translateY(30px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.portfolio-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 0; }
  .portfolio-list h2 {
    font-family: 'Fontin', sans-serif; }
  .portfolio-list article {
    display: inline-block;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 40px;
    width: 100%; }
    @media (min-width: 550px) {
      .portfolio-list article {
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        width: auto; } }
  .portfolio-list a {
    position: relative;
    display: block;
    float: left;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #333;
    transform: translateZ(0);
    max-width: 300px;
    width: 50%; }
    @media (min-width: 550px) {
      .portfolio-list a {
        width: auto;
        overflow: hidden; }
        .portfolio-list a:after {
          position: absolute;
          top: -25%;
          left: -25%;
          content: " ";
          width: 600px;
          height: 600px;
          transform: rotate(-45deg) translateY(75%);
          background: rgba(252, 189, 116, 0.8);
          transition: transform .4s; }
        .portfolio-list a:hover:after {
          transform: rotate(-45deg) translateY(0); } }
    .portfolio-list a .title {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      z-index: 300;
      color: #333;
      font-size: 1.5em;
      width: 100%;
      padding-left: 15px; }
      @media (min-width: 550px) {
        .portfolio-list a .title {
          left: 50%;
          top: 50%;
          width: 80%;
          transform: translate(-50%, -50%);
          text-align: center; }
          .portfolio-list a .title > * {
            transform: translateY(-30px);
            opacity: 0; } }
    .portfolio-list a .service-icon {
      display: none; }
      @media (min-width: 550px) {
        .portfolio-list a .service-icon {
          display: block; } }
    .portfolio-list a .name {
      font-size: 1.2em;
      letter-spacing: -1px;
      margin-bottom: 0; }
      .portfolio-list a .name:after {
        display: none; }
    .portfolio-list a .tagline {
      font-style: italic;
      font-size: .8em;
      margin-top: 0; }
    @media (min-width: 550px) {
      .portfolio-list a:hover .title > * {
        animation-name: fade;
        animation-duration: .4s;
        animation-fill-mode: forwards; }
        .portfolio-list a:hover .title > *:nth-child(1) {
          animation-delay: 0.1s; }
        .portfolio-list a:hover .title > *:nth-child(2) {
          animation-delay: 0.2s; }
        .portfolio-list a:hover .title > *:nth-child(3) {
          animation-delay: 0.3s; }
        .portfolio-list a:hover .title > *:nth-child(4) {
          animation-delay: 0.4s; } }
  .portfolio-list .code-project a:after {
    background: rgba(165, 209, 114, 0.8); }
  .portfolio-list img {
    border-radius: 50%;
    display: block; }

.service-icon {
  font-size: 50px;
  text-align: center;
  display: block; }

.mock {
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.6)); }

.boxed {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
  max-width: 96%; }

.chrome-mock {
  position: relative;
  max-width: 900px;
  max-height: 564px; }

.chrome-view {
  position: absolute;
  top: 19.5%;
  width: 100%;
  height: 80.5%;
  background: #FFF;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden; }
  .chrome-view img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%; }

.chrome-title, .chrome-nav {
  position: absolute;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%; }

.chrome-title {
  top: 4.2%;
  left: 3%;
  max-width: 10%;
  color: #888;
  overflow: hidden;
  white-space: nowrap; }

.chrome-nav {
  top: 12.7%;
  left: 18%;
  color: #000; }
  .chrome-nav::before {
    content: 'http://www.';
    color: #AAA; }

.iphone-mock {
  position: relative;
  max-width: 250px;
  max-height: 528px; }

.iphone-view {
  position: absolute;
  top: 11%;
  left: 4%;
  width: 91%;
  height: 77%;
  background: #FFF;
  overflow: hidden; }
  .iphone-view img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%; }

.iphone-wrap {
  width: 30%;
  max-width: 250px;
  position: absolute;
  top: 15%;
  right: 0%; }

.chrome-wrap {
  *zoom: 1;
  width: auto;
  max-width: 900px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .chrome-wrap:before, .chrome-wrap:after {
    content: '';
    display: table; }
  .chrome-wrap:after {
    clear: both; }
  @media (min-width: 0) and (max-width: 899px) {
    .chrome-wrap {
      max-width: 95%; } }

/*
<div class="chrome-mock">
	<img src="chrome-mock.svg" alt="" class="mock">
	<div class="chrome-title">Google Inc.</div>
	<div class="chrome-nav">google.com.br</div>
	<div class="chrome-view">
		<img src="R2A Engenharia 1.png" alt="">
	</div>
</div>
<div class="iphone-mock">
	<img src="iphone-mock.svg" alt="" class="mock">
	<div class="iphone-view">
		<img src="R2A Engenharia MOB.png" alt="">
	</div>
</div>
*/
.social {
  padding-left: 0; }
  .social li {
    display: inline-block;
    font-size: 25px;
    text-align: center;
    margin-right: 5px; }
    .social li a {
      display: block;
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: all .4s;
      color: gray; }
      .social li a:after, .social li a:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid gray;
        transition: all .4s; }
      .social li a:before {
        width: 80%;
        height: 80%; }
      .social li a:hover:before {
        border-color: #FFF; }
      .social li a.social-linkedin:hover {
        color: #FFF;
        background: #1a84bc; }
        .social li a.social-linkedin:hover:after {
          border-color: #1a84bc; }
      .social li a.social-github:hover {
        color: #FFF;
        background: #000; }
        .social li a.social-github:hover:after {
          border-color: #000; }
      .social li a.social-twitter:hover {
        color: #FFF;
        background: #1da1f2; }
        .social li a.social-twitter:hover:after {
          border-color: #1da1f2; }
      .social li a.social-pinterest:hover {
        color: #FFF;
        background: #d01d15; }
        .social li a.social-pinterest:hover:after {
          border-color: #d01d15; }
      .social li a span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

.site-header {
  padding: 10px 0;
  transition: .3s all;
  position: fixed;
  width: 100%;
  z-index: 200;
  *zoom: 1; }
  .site-header:before, .site-header:after {
    content: '';
    display: table; }
  .site-header:after {
    clear: both; }
  .site-header .container {
    position: relative; }

.top-bar-bg {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0% 0%;
  transform: rotateX(180deg);
  transform-style: preserve-3d;
  transition: all 1s; }
  .sticky .top-bar-bg {
    background: #2f6db5;
    transform: rotateX(0deg); }

.brand {
  max-width: 220px; }
  .brand a {
    display: block;
    width: 100%;
    height: 50px; }
  .brand img {
    transition: opacity 1s; }
  .sticky .brand {
    background: transparent url("/svg/logotipo.svg?c=fff") left top no-repeat;
    background-size: 100%; }
    .sticky .brand img {
      opacity: 0; }

.main-nav {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(200%, -50%);
  z-index: 340;
  text-align: center;
  width: 280px;
  padding: 10px;
  transition: transform 0.3s; }
  .nav-opened .main-nav {
    transform: translate(-50%, -50%); }
  .main-nav ul {
    width: 100%;
    padding: 0 15px; }
  .main-nav a {
    padding: 10px;
    display: block;
    color: #FFF;
    text-transform: uppercase;
    transition: color 1s; }
    @media (min-width: 550px) {
      .main-nav a {
        color: #2f6db5; } }
    .main-nav a:hover {
      text-decoration: none; }
    .sticky .main-nav a {
      transition: .3s all;
      color: #FFF; }
  .main-nav li {
    border-bottom: 1px solid #3567AF;
    list-style: none;
    display: block;
    margin-left: 0;
    text-align: center; }
    .main-nav li:last-of-type {
      border-bottom: none; }
  @media (min-width: 550px) {
    .main-nav {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 0;
      padding-top: 5px;
      transform: none;
      width: auto; }
      .main-nav ul {
        text-align: right;
        margin: 0;
        right: 0;
        padding-left: 0; }
      .main-nav li {
        display: inline-block;
        margin-left: 20px;
        border-bottom: none; } }

body > header,
body > .container {
  transition: transform 0.3s; }

.menu-overlay {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 300;
  background: #3FACE2;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: scale(1);
  transition: transform .2s; }
  .nav-opened .menu-overlay {
    transform: scale(50);
    transition: transform .5s; }
  @media (min-width: 550px) {
    .menu-overlay {
      display: none; } }

.portfolio-wrap {
  background: #018bd3;
  color: #FFF; }
  .portfolio-wrap a {
    color: #FFF; }
  .inverse .portfolio-wrap {
    color: #333; }
    .inverse .portfolio-wrap a {
      color: #333; }
  .portfolio-wrap .container, .portfolio-wrap header {
    position: relative; }
  .portfolio-wrap h1 {
    margin: 0;
    padding: 0; }
    .portfolio-wrap h1:after {
      display: none; }
  .portfolio-wrap #svgLogo {
    display: none; }
    @media (min-width: 550px) {
      .portfolio-wrap #svgLogo {
        display: inline; } }
  .portfolio-wrap header {
    position: fixed;
    width: 100%;
    background: #018bd3;
    padding-bottom: 10px;
    z-index: 200; }
  .portfolio-wrap > .container:first-of-type {
    padding-top: 80px; }
    @media (min-width: 550px) {
      .portfolio-wrap > .container:first-of-type {
        padding-top: 120px; } }

.project-title {
  line-height: .6em;
  margin-bottom: 30px;
  color: #FFF; }
  .inverse .project-title {
    color: #333; }
  .project-title img {
    width: 160px;
    transform: translateY(7px); }
  .project-title span {
    border-color: #FFF;
    font-family: 'Fontin', sans-serif;
    font-size: .25em;
    letter-spacing: .002em; }
    @media (min-width: 550px) {
      .project-title span {
        font-size: .5em;
        border-left: 1px solid #FFF;
        padding-left: 20px; } }
    .inverse .project-title span {
      border-color: #333; }

.slide {
  background: #EEE;
  text-align: center;
  padding: 5% 0; }
  .slide-init {
    background: #EEE url("/svg/up.svg?c=018bd3") no-repeat;
    background-size: contain;
    background-position: -2px 0;
    padding-bottom: 60px; }
    @media (min-width: 550px) {
      .slide-init {
        padding-bottom: 0; } }
  .slide-fim {
    background: #EEE url("/svg/down.svg?c=018bd3") no-repeat bottom center;
    background-size: cover; }
  .slide-content {
    *zoom: 1;
    width: auto;
    max-width: 1180px;
    float: none;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0; }
    .slide-content:before, .slide-content:after {
      content: '';
      display: table; }
    .slide-content:after {
      clear: both; }
    .slide-content.slide-mocks {
      position: relative; }

.portfolio-close {
  position: absolute;
  bottom: 13px;
  right: 10px;
  cursor: pointer;
  text-transform: uppercase;
  color: #FFF; }
  .inverse .portfolio-close {
    color: #333; }
  .portfolio-close:hover {
    text-decoration: none; }
  .portfolio-close span {
    display: inline-block;
    vertical-align: middle; }
    .portfolio-close span:first-of-type {
      display: none; }
      @media (min-width: 550px) {
        .portfolio-close span:first-of-type {
          display: inline-block; } }
  .portfolio-close .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 10px; }
    .portfolio-close .icon:after, .portfolio-close .icon:before {
      top: 50%;
      left: 50%;
      position: relative;
      display: block;
      content: ' ';
      width: 40px;
      height: 1px;
      background: #FFF;
      transform: translate(-50%, -50%) rotate(45deg); }
      .inverse .portfolio-close .icon:after, .inverse .portfolio-close .icon:before {
        background: #333; }
    .portfolio-close .icon:before {
      transform: translate(-50%, -50%) rotate(-45deg); }

#project-container {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  display: none; }
  .project-opened #project-container {
    display: block; }

body.project-opened, html.project-opened {
  overflow: hidden;
  width: 100%; }

.credits {
  background: rgba(255, 255, 255, 0.2);
  margin: 10px 0;
  padding: 10px 20px; }
  .inverse .credits {
    background: rgba(0, 0, 0, 0.2); }

.tag-list {
  list-style: none;
  margin: 10px 0;
  padding-left: 0; }

.tag {
  display: inline-block;
  background: #999;
  color: #FFF;
  border-radius: 3px;
  padding: 2px 7px; }

a.external {
  color: #FFF; }
  a.external:before {
    font-family: 'rriogo' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    margin-right: 10px; }

.inverse p {
  color: #333; }

.inverse a.external {
  color: #333; }

.open-project {
  overflow: hidden; }

@keyframes slide {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

.open-project #project-show {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  overflow: auto; }

.open-project.loading #project-show {
  opacity: 0.9;
  background: #FFF url("../img/ajax-loader.gif") no-repeat center center; }
  .open-project.loading #project-show > * {
    display: none; }

.loaded #project-show > * {
  animation-name: slide;
  animation-duration: 1s; }

body {
  background: transparent url("../img/paper.png") left top; }
  body > section {
    min-height: 100vh; }

#home-page {
  overflow: hidden;
  background-color: #d5e4f4;
  background-image: url("/svg/bg.svg?c=7e6cb0");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light; }
  #home-page .wrap {
    overflow: hidden;
    padding-top: 50vh;
    height: 100vh;
    width: 100vw;
    position: relative; }
  #home-page .container {
    transform: translateY(-50%);
    z-index: 100; }

/* No files to import found in pages//* */
