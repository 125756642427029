$icomoon-font-path: "../fonts" !default;

$icon-link-external: "\e914";
$icon-envelope: "\e901";
$icon-star-half: "\e90f";
$icon-star-full: "\e910";
$icon-star-empty: "\e911";
$icon-profile: "\e900";
$icon-briefcase: "\e902";
$icon-twitter: "\e903";
$icon-dribbble: "\e904";
$icon-google-plus: "\e905";
$icon-embed: "\e906";
$icon-pinterest: "\e907";
$icon-behance: "\e908";
$icon-youtube-play: "\e90a";
$icon-linkedin: "\e90b";
$icon-facebook: "\e90c";
$icon-github: "\e90d";
$icon-code: "\e912";
$icon-design: "\e913";
$icon-star-full: "\e910";
$icon-star-empty: "\e911";
$icon-profile: "\e900";
$icon-briefcase: "\e902";
$icon-twitter: "\e903";
$icon-dribbble: "\e904";
$icon-google-plus: "\e905";
$icon-embed: "\e906";
$icon-pinterest: "\e907";
$icon-behance: "\e908";
$icon-youtube-play: "\e90a";
$icon-linkedin: "\e90b";
$icon-facebook: "\e90c";
$icon-github: "\e90d";
$icon-youtube: "\e909";
$icon-pinterest-p: "\e90e";

@font-face {
    font-family: 'rriogo';
    src:    url('#{$icomoon-font-path}/rriogo.eot?llrdwo');
    src:    url('#{$icomoon-font-path}/rriogo.eot?llrdwo#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/rriogo.ttf?llrdwo') format('truetype'),
        url('#{$icomoon-font-path}/rriogo.woff?llrdwo') format('woff'),
        url('#{$icomoon-font-path}/rriogo.svg?llrdwo#rriogo') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'rriogo' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-link-external {
    &:before {
        content: $icon-link-external;
    }
}
.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}
.icon-star-half {
    &:before {
        content: $icon-star-half;
    }
}
.icon-star-full {
    &:before {
        content: $icon-star-full;
    }
}
.icon-star-empty {
    &:before {
        content: $icon-star-empty;
    }
}
.icon-profile {
    &:before {
        content: $icon-profile;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-dribbble {
    &:before {
        content: $icon-dribbble;
    }
}
.icon-google-plus {
    &:before {
        content: $icon-google-plus;
    }
}
.icon-embed {
    &:before {
        content: $icon-embed;
    }
}
.icon-pinterest {
    &:before {
        content: $icon-pinterest;
    }
}
.icon-behance {
    &:before {
        content: $icon-behance;
    }
}
.icon-youtube-play {
    &:before {
        content: $icon-youtube-play;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-github {
    &:before {
        content: $icon-github;
    }
}
.icon-code {
    &:before {
        content: $icon-code;
    }
}
.icon-design {
    &:before {
        content: $icon-design;
    }
}
.icon-star-full {
    &:before {
        content: $icon-star-full;
    }
}
.icon-star-empty {
    &:before {
        content: $icon-star-empty;
    }
}
.icon-profile {
    &:before {
        content: $icon-profile;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-dribbble {
    &:before {
        content: $icon-dribbble;
    }
}
.icon-google-plus {
    &:before {
        content: $icon-google-plus;
    }
}
.icon-embed {
    &:before {
        content: $icon-embed;
    }
}
.icon-pinterest {
    &:before {
        content: $icon-pinterest;
    }
}
.icon-behance {
    &:before {
        content: $icon-behance;
    }
}
.icon-youtube-play {
    &:before {
        content: $icon-youtube-play;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-github {
    &:before {
        content: $icon-github;
    }
}
.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}
.icon-pinterest-p {
    &:before {
        content: $icon-pinterest-p;
    }
}

