body {
    background: transparent url('../img/paper.png') left top;

    > section {
        min-height: 100vh;
    }
}

#home-page {
    overflow: hidden;
    background-color: lighten($main-color, 45%);
    background-image: url('/svg/bg.svg?c=7e6cb0');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;

    .wrap {
       overflow: hidden;
        padding-top: 50vh;
        height: 100vh;
        width: 100vw;
        position: relative;
    }

    .container {
        transform: translateY(-50%);
        z-index: 100;
    }
}
