@import 'jeet/scss/jeet/index.scss';
@import 'normalize-scss/_normalize.scss';
@import 'breakpoint-sass/stylesheets/_breakpoint.scss';

$main-color: #2f6db5;
$main-color-lighten: #5da3ce;

$second-color: #493f99;
$second-color-lighten: #7e6cb0;

$third-color: #7bc142;
$third-color-lighten: #a5d172;

$fourth-color: #f6902b;
$fourth-color-lighten: #fcbd74;

$text-color: #333;

$menu-width: 280px;
$animation-time: .3s;

// Jeet Vars
$jeet-gutter:       3      !default;
$jeet-parent-first: false  !default;
$jeet-max-width:    1180px;

// By Skeleton (mobile-first)
$mobile: 400px;
$phablet: 550px;
$tablet: 750px;
$desktop: 1000px;
$desktophd: 1200px;

// Atomic CSS
@import '_core/_base.scss';
@import '_core/_font.scss';
@import '_core/_grid.scss';
@import '_core/_icomoon.scss';
@import '_core/_utils.scss';

@import 'atoms/_footer.scss';
@import 'atoms/_forms.scss';
@import 'atoms/_links.scss';
@import 'atoms/_mail-contact.scss';
@import 'atoms/_menu-trigger.scss';
@import 'atoms/_tags.scss';
@import 'atoms/_typography.scss';

@import 'molecules/_charts.scss';
@import 'molecules/_codedesign-animation.scss';
@import 'molecules/_portfolio-list.scss';
@import 'molecules/_portfolio-mockups.scss';
@import 'molecules/_social.scss';

@import 'organisms/_header.scss';
@import 'organisms/_navigation.scss';

@import 'templates/_portfolio.scss';
@import 'templates/_site.scss';

/* No files to import found in pages//* */